<template>
  <nav v-if="worldName" class="flex-align-center">
    <router-link :to="`/${worldPath}`" class="nav-logo one-line">
      <img
        v-lazy="worldLogo"
        width="32"
        height="32"
        class="fit-cover radius50 mr-8"
      />
      <span class="one-line">{{ worldName }}</span>
    </router-link>
    <div class="dropdown menu-tab" v-if="menuList && menuList.length">
      <p
        class="dropdown-toggle one-line"
        @click.stop="
          menuShow = !menuShow;
          accountShow = false;
        "
      >
        <img src="@/assets/img/icon/menu.svg" width="16" />
      </p>
      <ul class="dropdown-menu text-left" v-show="menuShow">
        <li
          v-for="(item, i) in menuList"
          :key="i"
          @click.stop="menuShow = false"
        >
          <a :href="item.url">{{ item.title }}</a>
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      menuShow: false,
    };
  },
  computed: {
    ...mapGetters(["menuList"]),
  },
  mounted() {
    this.$nextTick(() => {
      document.documentElement.onclick = () => {
        this.menuShow = false;
      };
    });
  },
};
</script>
<style scoped>
nav {
  position: fixed;
  padding: 16px 24px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  background: var(--mainColor);
  color: #fff;
}
nav .nav-logo {
  color: #fff;
  font: bold 18px/28px var(--fontBold);
  display: flex;
  align-items: center;
}
.menu-tab {
  font: 500 14px/16px var(--fontBold);
}
@media (max-width: 767px) {
  nav {
    padding: 16px;
  }
  /* .menu-tab {
    padding-right: 12px;
    border-right: 1px solid rgba(236, 233, 235, 30%);
  } */
  /* .menu-tab img {
    width: 18px;
  } */
}
</style>
