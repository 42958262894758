import Vue from "vue";
import Vuex from "vuex";
import i18n from "../assets/js/i18n";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lang: i18n.locale,
    twitter: "",
    discord: "",
    worldName: "",
    worldLogo: "",
    worldDes: "",
    themeColor: "",
    themeFont: "",
    theme: "", //0:default 1:map
    themeType: 0, //0:ID 1:X-Y Axis
    mapTypeInfo: null,
    chainId: 52, //数据库存储的id
    marketSubdomain: "",
    agentSubdomain: "",
    menuList: null,
  },
  getters: {
    lang(state) {
      return state.lang;
    },
    twitter(state) {
      return state.twitter;
    },
    discord(state) {
      return state.discord;
    },
    worldName(state) {
      return state.worldName;
    },
    worldLogo(state) {
      return state.worldLogo;
    },
    worldDes(state) {
      return state.worldDes;
    },
    themeColor(state) {
      return state.themeColor;
    },
    themeFont(state) {
      return state.themeFont;
    },
    theme(state) {
      return state.theme;
    },
    themeType(state) {
      return state.themeType;
    },
    mapTypeInfo(state) {
      return state.mapTypeInfo;
    },
    chainId(state) {
      return state.chainId;
    },
    marketSubdomain(state) {
      return state.marketSubdomain;
    },
    agentSubdomain(state) {
      return state.agentSubdomain;
    },
    menuList(state) {
      return state.menuList;
    },
  },
  mutations: {
    setLang(state, val) {
      i18n.setUserLanguage(val);
      state.lang = val;
    },
    setInfo(state, item) {
      state.twitter = item?.twitter;
      state.discord = item?.discord;
      state.worldName = item?.name;
      state.worldLogo = item?.img;
      state.worldDes = item?.description;
      state.theme = item?.theme;
      state.themeType = item?.type;
      state.mapTypeInfo = item?.typeInfo;
      state.themeColor = item?.theme_info?.theme_color;
      state.themeFont = item?.theme_info?.theme_font;
      state.chainId = item?.chainId || 52;
      state.marketSubdomain = item?.location_subdomain;
      state.agentSubdomain = item?.agent_subdomain || "";
      state.menuList = item?.menus;
    },
  },
  actions: {
    setLang(context, val) {
      context.commit("setLang", val);
    },
    setInfo(context, val) {
      context.commit("setInfo", val);
    },
  },
});
